import * as React from "react";
import NavButton from "../utils/NavButton";

import gsap from "gsap";

const WellnessSlider = (props) => {
  const [currentCard, setCurrentCard] = React.useState(0);
  const [isMobile, setMobile] = React.useState(false);
  const [clickBlocked, setClickBlocked] = React.useState(false);
  let cardsLength = 5;
  let cards = [];
  let cardsWrapper = React.useRef();
  React.useEffect(() => {
    cards = document.querySelectorAll(".wellness-slider_gallery li");
    setMobile(window.innerWidth < 768 ? true : false);
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (isMobile) {
            observer.unobserve(document.querySelector(".wellness-slider"));
          } else {
            manageAppendRemove();
          }
        }
      });
    });
    if (!isMobile) {
      observer.observe(document.querySelector(".wellness-slider"));
    } else {
      observer.unobserve(document.querySelector(".wellness-slider"));
      document.querySelector(".wellness-slider_gallery").style.transform =
        "initial";
    }
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth < 768 ? true : false);
      if (!isMobile) {
        observer.observe(document.querySelector(".wellness-slider"));
      } else {
        observer.unobserve(document.querySelector(".wellness-slider"));
        document.querySelector(".wellness-slider_gallery").style.transform =
          "initial";
      }
    });
  });

  const getCurrentTransformX = (element) => {
    let style = window.getComputedStyle(element).getPropertyValue("transform");
    console.log(style);
    let translateX = new DOMMatrix(style).m41;
    console.log(translateX);
    return translateX;
  };

  const blockAppear = (block, tl) => {
    manageAppendRemove();
    console.log(block);
    let p = [];
    [...block].forEach((el) => {
      p.push(el.querySelector("p"));
    });
    tl.fromTo(
      p,
      {
        autoAlpha: 0,
        y: -5,
      },
      {
        autoAlpha: 1,
        y: 0,
      },
      "-=0.4"
    );
  };
  const blockDisappear = (block, tl) => {
    manageAppendRemove();
    console.log(block);
    let p = [];
    [...block].forEach((el) => {
      p.push(el.querySelector("p"));
    });
    tl.fromTo(
      p,
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
      }
    );
  };

  const next = (props) => {
    if (!clickBlocked) {
      setClickBlocked(true);
      // Translate to the left .wellness-slider_gallery width the current card with + 28px
      let transform =
        (getCurrentTransformX(cardsWrapper.current)
          ? getCurrentTransformX(cardsWrapper.current)
          : 1) -
        (cards[currentCard].offsetWidth + 28);
      //cardsWrapper.current.style.transform = `translateX(-${transform}px)`;

      //add first element
      let tl = gsap.timeline();
      blockDisappear(
        document.querySelectorAll(`[data-index="${currentCard}"`),
        tl
      );

      setCurrentCard(currentCard < cardsLength - 2 ? currentCard + 1 : 0);
      tl.to(
        cardsWrapper.current,
        {
          duration: 0.8,
          ease: "power3.out",
          x: transform,
        },
        "-=0.45"
      );
      blockAppear(
        document.querySelectorAll(
          `[data-index="${currentCard < cardsLength - 2 ? currentCard + 1 : 0}"`
        ),
        tl
      );
      setTimeout(() => {
        setClickBlocked(false);
      }, 1000);
    }
  };

  const previous = (props) => {
    if (!clickBlocked) {
      setClickBlocked(true);

      // cardsWrapper.current.style.transform = `translateX(-${transform}px)`;
      let tl = gsap.timeline();
      console.log(
        document.querySelectorAll(`[data-index="${currentCard}"`),
        currentCard
      );
      blockDisappear(
        document.querySelectorAll(`[data-index="${currentCard}"`),
        tl
      );
      setCurrentCard(currentCard > 0 ? currentCard - 1 : 3);
      console.log(currentCard);
      let transform =
        (getCurrentTransformX(cardsWrapper.current)
          ? getCurrentTransformX(cardsWrapper.current)
          : 0) +
        (cards[currentCard].offsetWidth + 28);
      tl.to(
        cardsWrapper.current,
        {
          duration: 0.8,
          ease: "power3.out",
          x: transform,
        },
        "-=0.45"
      );
      blockAppear(
        document.querySelectorAll(
          `[data-index="${currentCard > 0 ? currentCard - 1 : 3}"]`
        ),
        tl
      );
      setTimeout(() => {
        setClickBlocked(false);
      }, 1000);
    }
  };

  const openDetail = (detail) => {
    console.log("open Details", detail);
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    let baseHeight = detail
      .querySelector("summary")
      .getBoundingClientRect().height;
    console.log("BaseHeight", baseHeight);
    detail.setAttribute("open", "true");
    let targetHeight = detail.getBoundingClientRect().height;
    let tl = gsap.timeline();
    tl.fromTo(
      detail,
      {
        height: baseHeight,
      },
      {
        height: targetHeight,
      }
    );
  };

  const closeDetail = (detail) => {
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    gsap.killTweensOf(detail.querySelector("p"));
    detail.querySelector("p").style = " ";
    console.log("close Detail", detail);
    let tl = gsap.timeline();
    tl.fromTo(
      detail,
      {
        height: detail.getBoundingClientRect().height,
        ease: "easeOutExpo",
      },
      {
        height: detail.querySelector("summary").getBoundingClientRect().height,
        ease: "easeOutExpo",
        onComplete: () => {
          detail.removeAttribute("open");
        },
      }
    );
  };

  const onClickDetail = (e, index) => {
    e.preventDefault();
    const details = document.querySelectorAll(".wellness-detail");
    const current = [...details][index];
    console.log("current", current, details);
    openDetail(current);
    [...details]
      .filter((x) => x != current)
      .forEach((el) => {
        closeDetail(el);
      });
  };

  const manageAppendRemove = () => {
    if (!isMobile) {
      let ul = cardsWrapper.current.querySelector("ul");

      if (getCurrentTransformX(cardsWrapper.current) >= 0) {
        console.log(window.getComputedStyle(ul).width.replace("px", ""));
        gsap.set(cardsWrapper.current, {
          x: -(
            window.getComputedStyle(ul).width.replace("px", "") * 2 +
            28 * 2
          ),
        });
      }

      if (currentCard === 0) {
        cardsWrapper.current.appendChild(ul.cloneNode(true));
      } else if (currentCard === cards.length - 1) {
        cardsWrapper.current.insertBefore(ul.cloneNode(true), ul.firstChild);
      }
    }
  };

  return (
    <div className="wellness-slider">
      <div className="wellness-slider_header">
        <h2
          data-splitted-title
          data-s-tag="wellness"
          data-intersection-animation
          data-i-trigger=".wellness-slider"
          data-i-type="title"
        >
          {props.pageContext.locale === "fr"
            ? "Détente et bien-être"
            : "Relaxation and wellness"}
        </h2>
        <p
          data-splitted-block
          data-s-tag="wellnesstext"
          data-intersection-animation
          data-i-trigger=".wellness-slider"
          data-i-type="text"
        >
          {props.pageContext.locale === "fr"
            ? "Offrez-vous un moment de calme et de détente et profitez d’un cours privé dans votre suite ou dans le jardin avec nos coachs diplômés"
            : "Treat yourself to a moment of calm and relaxation and enjoy a private class in your suite or in the garden with our certified coaches"}
        </p>
      </div>
      {!isMobile ? (
        <div
          className="wellness-slider_nav"
          data-intersection-animation
          data-i-trigger=".wellness-slider"
          data-i-type="opacity"
          data-i-value="0"
          data-i-duration="1.5"
          data-i-delay="0.5"
        >
          <NavButton
            state={false}
            setState={previous}
            className={currentCard < 1 ? " isBlocked" : null}
            disabled={clickBlocked}
          />
          <NavButton
            state={true}
            setState={next}
            className={currentCard >= cardsLength - 1 ? " isBlocked" : null}
            disabled={clickBlocked}
          />
        </div>
      ) : (
        ""
      )}

      {!isMobile ? (
        <div className="wellness-slider_gallery" ref={cardsWrapper}>
          <ul>
            {props.activities.map((activity, index) => {
              return (
                <li key={index} data-index={index}>
                  {/* <GatsbyImage
                      key={index}
                      image={activity.Image.asset.gatsbyImageData}
                      alt={props.localeTextGetter(activity.alt)}
                      loading="eager"
                /> */}
                  <div>
                    <img
                      src={
                        activity.Image.asset.gatsbyImageData.images.fallback.src
                      }
                      alt={"Image de la discipline" + activity.title}
                    />
                  </div>
                  <h3>{props.localeTextGetter(activity.title)}</h3>
                  <p data-splitted-block data-s-tag={"sport-" + (index + 1)}>
                    {props.localeTextGetter(activity.description)}
                  </p>
                </li>
              );
            })}
          </ul>
          <ul>
            {props.activities.map((activity, index) => {
              console.log("Image Asset", activity.Image.asset.gatsbyImageData);
              return (
                <li key={index} data-index={index}>
                  {/* <GatsbyImage
                      key={index}
                      image={activity.Image.asset.gatsbyImageData}
                      alt={props.localeTextGetter(activity.alt)}
                      loading="eager"
                /> */}
                  <div>
                    <img
                      src={
                        activity.Image.asset.gatsbyImageData.images.fallback.src
                      }
                      alt={"Image de la discipline" + activity.title}
                    />
                  </div>
                  <h3>{props.localeTextGetter(activity.title)}</h3>
                  <p data-splitted-block data-s-tag={"sport-" + (index + 10)}>
                    {props.localeTextGetter(activity.description)}
                  </p>
                </li>
              );
            })}
          </ul>
          <ul>
            {props.activities.map((activity, index) => {
              return (
                <li key={index} data-index={index}>
                  {/* <GatsbyImage
                      key={index}
                      image={activity.Image.asset.gatsbyImageData}
                      alt={props.localeTextGetter(activity.alt)}
                      loading="eager"
                /> */}
                  <div>
                    <img
                      src={
                        activity.Image.asset.gatsbyImageData.images.fallback.src
                      }
                      alt={"Image de la discipline" + activity.title}
                    />
                  </div>
                  <h3>{props.localeTextGetter(activity.title)}</h3>
                  <p data-splitted-block data-s-tag={"sport-" + (index + 20)}>
                    {props.localeTextGetter(activity.description)}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="wellness-slider_gallery">
          <ul>
            {props.activities.map((activity, index) => {
              return (
                <li key={index}>
                  <details
                    className="wellness-detail"
                    onClick={(e) => {
                      onClickDetail(e, index);
                    }}
                  >
                    <summary>
                      <div>
                        {" "}
                        <h3>{props.localeTextGetter(activity.title)}</h3>{" "}
                        <div>
                          <svg
                            className="open"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8333 10.8334H4.16666V9.16675H15.8333V10.8334Z"
                              fill="#3A3636"
                            />
                          </svg>
                          <svg
                            className="close"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8333 10.8334H10.8333V15.8334H9.16666V10.8334H4.16666V9.16675H9.16666V4.16675H10.8333V9.16675H15.8333V10.8334Z"
                              fill="#3A3636"
                            />
                          </svg>
                        </div>
                      </div>
                    </summary>
                    <p>{props.localeTextGetter(activity.description)}</p>
                  </details>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WellnessSlider;
