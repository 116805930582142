import * as React from "react";
import { Helmet } from "react-helmet";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/src/all";
import imagesLoaded from "imagesloaded";

import parallaxAnimation from "../utils/parallaxAnimation";

import { graphql } from "gatsby";
import { Link } from "gatsby";

import { createLocaleTextGetter } from "../utils";

import WellnessSlider from "../components/blocks/WellnessSlider";

import HeaderImageOneDesktop from "../images/pages/accueil/desktop/header_image_one.jpg";
import HeaderImageTwoDesktop from "../images/pages/accueil/desktop/header_image_two.jpg";

import HeaderImageOneMobile from '../images/pages/accueil/mobile/header_image_one.jpg';
import HeaderImageTwoMobile from '../images/pages/accueil/mobile/header_image_two.jpg';

import SuitesImageOneDesktop from "../images/pages/accueil/desktop/suites_image_one.jpg";

import LivingImageOneDesktop from "../images/pages/accueil/desktop/living_image_one.jpg";

import RestauImageDesktop from "../images/pages/accueil/desktop/restau_image.jpg";
import ActivityImageDesktop from "../images/pages/accueil/desktop/activity_image.jpg";

import ActionButton from "../components/utils/ActionButton";

import "../styles/pages/accueil.scss";
// markup
const IndexPage = ({ data, pageContext, asscroll, context }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);
  let scrollTriggerImageElement = React.useRef();

  const lang = pageContext.locale === "fr" ? "" : "/en";

  let splittedData = [];

  React.useEffect(() => {
    const textAnimation = require("../utils/textAnimation.js").default;
    const intersectionAnimation =
      require("../utils/intersectionAnimation.js").default;
    if (window.innerWidth > 769) {
      let tl = gsap.timeline({
        onComplete: () => {
          ScrollTrigger.refresh();
        },
      });
      tl.set("main", { autoAlpha: 0 });
      imagesLoaded(".bi_accueil_header", () => {
        splittedData = textAnimation.splitAllText();
        tl.set("main", { autoAlpha: 1 });
        textAnimation.splitTitleApparition(
          splittedData.splittedTitles.find((el) => el.id === "h1").chars,
          tl
        );
        tl.fromTo(
          ".bi_accueil_header picture",
          { autoAlpha: 0, y: 100 },
          {
            autoAlpha: 1,
            y: 0,
            duration: 1,
            ease: "power2.inOut",
            stagger: 0.2,
          },
          "<"
        );
        textAnimation.splitBlockApparition(
          splittedData.splittedBlocks.find((el) => el.id === "header").lines
            .lines,
          "-=0.5",
          "word",
          tl
        );
        intersectionAnimation(splittedData);
      });
      parallaxAnimation.imagesParallax();
      parallaxAnimation.blockParallax();
    }

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 300);
  }, []);

  React.useEffect(() => {
    if (context.scroll && window.innerWidth > 770) {
      context.gsap.to(
        scrollTriggerImageElement.current.querySelector("picture"),
        {
          width: "100vw",
          height: "100vh",
          scrollTrigger: {
            trigger: scrollTriggerImageElement.current,
            start: "top top",
            end: `+=${1200}vh`,
            scrub: true,
            pin: true,
          },
        }
      );
    }
    ScrollTrigger.refresh();
  });

  return (
    <main className="bi_accueil">
      <Helmet htmlAttributes={{ lang: pageContext.Locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Les Suites de l'île Barbe"
            : "The Suites de l'île Barbe"}
        </title>
        <meta type="description" content="Les Suites de l'île Barbe" />
      </Helmet>
      <h1 data-splitted-title data-s-tag="h1">
        {pageContext.locale === "fr"
          ? "Une parenthèse hors du temps"
          : "A time out of parentheses"}
      </h1>
      <div className="bi_accueil_header">
        <picture
          data-parallax="true"
          data-p-direction="top"
          data-p-vitesse="0.25"
        >
          {/* add  */}
          <source srcSet={HeaderImageOneMobile} media="(max-width: 769px)" />
          <img src={HeaderImageOneDesktop} alt="L'île barbe, le grand salon" />
        </picture>
        <div>
          <p data-splitted-block data-s-tag="header">
            {pageContext.locale === "fr"
              ? "Situé au coeur d’un écrin de verdure, notre établissement vous accueille dans le cadre exceptionnel de l’Ile Barbe, une ancienne abbaye inscrite à l’inventaire des monuments historiques située à 10 minutes du centre ville de Lyon."
              : "Located in the heart of a green setting, our establishment welcomes you in the exceptional setting of the Île Barbe, a former abbey listed as a historical monument located 10 minutes from downtown Lyon."}
          </p>
          <picture
            data-parallax="true"
            data-p-direction="top"
            data-p-vitesse="0.25"
          >
            <source srcSet={HeaderImageTwoMobile} media="(max-width: 769px)" />
            <img
              src={HeaderImageTwoDesktop}
              alt="L'île barbe, le grand salon"
            />
          </picture>
        </div>
      </div>
      <section
        className="bi_accueil_suites"
        data-intersection-animation
        data-i-trigger=".bi_accueil_suites"
        data-i-type="opacity"
        data-i-value="100"
        data-i-duration="1.5"
      >
        <picture
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="0.5"
        >
          <img src={SuitesImageOneDesktop} alt="L'île barbe, la chambre" />
        </picture>

        <div
          className="bi_accueil_suites_content"
          data-parallax="true"
          data-p-direction="top"
          data-p-vitesse="0.8"
        >
          <h2 data-splitted-title data-s-tag="suites">
            {pageContext.locale === "fr"
              ? "Des suites modernes et empreintes d'histoire"
              : "Modern suites with a sense of history"}
          </h2>
          <p>
            {pageContext.locale === "fr"
              ? "Entièrement rénovées et décorées par un architecte d’intérieur, nos suites offrent tout le confort moderne tout en conservant le caractère authentique des lieux."
              : "Completely renovated and decorated by an interior designer, our suites offer all the modern comforts while maintaining the authentic character of the place."}
          </p>
          <ActionButton
            color="black"
            to={lang + "/les_suites"}
            type="internal"
            children="Les Suites"
          />
        </div>
      </section>
      <section className="bi_acceuil_living" ref={scrollTriggerImageElement}>
        <picture>
          <img src={LivingImageOneDesktop} alt="" />
        </picture>
      </section>
      <section className="bi_acceuil_around">
        <div className="bi_accueil_around_header">
          <h2
            data-splitted-title
            data-s-tag="autour"
            data-intersection-animation
            data-i-trigger=".bi_accueil_around_header"
            data-i-type="title"
          >
            {pageContext.locale === "fr"
              ? "Autour de l'île"
              : "Around the island"}
          </h2>
          <p
            data-splitted-block
            data-s-tag="autourtext"
            data-intersection-animation
            data-i-trigger=".bi_accueil_around_header"
            data-i-type="text"
          >
            {pageContext.locale === "fr"
              ? "Les environs de l’Île Barbe et de la ville de Lyon regorgent de trésors gastronomiques et d’activités culturelles. La situation de l’établissement, au bord de l’eau, est parfaite pour profiter des activités offertes par la rivière !"
              : "The surroundings of Île Barbe and the city of Lyon are full of gastronomic gastronomic treasures and cultural activities. The location of the hotel location of the hotel is perfect to enjoy the activities offered by the river! offered by the river!"}
          </p>
        </div>
        <div
          className="bi_accueil_around_content"
          data-intersection-animation
          data-i-trigger=".bi_accueil_around_content"
          data-i-type="opacity"
          data-i-value="0"
          data-i-duration="1.5"
        >
          <Link to={lang + "/restauration"}>
            <picture>
              <img src={RestauImageDesktop} alt="" />
            </picture>
            <div>
              <h3 data-splitted-title>
                {pageContext.locale === "fr" ? "Restauration" : "Catering"}
              </h3>
              <p>
                <span className="line"></span>
                <span className="word">
                  {pageContext.locale === "fr" ? "Découvrir" : "Discover"}
                </span>
              </p>
            </div>
          </Link>
          <Link to={lang + "/activities"}>
            <picture>
              <img src={ActivityImageDesktop} alt="" />
            </picture>
            <div>
              <h3 data-splitted-title>
                {pageContext.locale === "fr" ? "Activités" : "Activities"}
              </h3>
              <p>
                <span className="line"></span>
                <span className="word">
                  {pageContext.locale === "fr" ? "Découvrir" : "Discover"}
                </span>
              </p>
            </div>
          </Link>
        </div>
      </section>
      <section className="bi_wellness">
        <WellnessSlider
          pageContext={pageContext}
          activities={data.sanityIndexDocumentType.activities}
          localeTextGetter={localeTextGetter}
        />
      </section>
    </main>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    sitePage {
      pageContext
    }
    sanityIndexDocumentType {
      activities {
        alt {
          _key
          _type
          fr
          en
        }
        description {
          _key
          _type
          fr
          en
        }
        title {
          _key
          _type
          fr
          en
        }
        Image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
